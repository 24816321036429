// extracted by mini-css-extract-plugin
export var byTheNumbers = "scholarship-module--by-the-numbers--V-wOy";
export var faqs = "scholarship-module--faqs--VW9Qt";
export var grant = "scholarship-module--grant--fwvpX";
export var grantLeft = "scholarship-module--grant-left--IWKgC";
export var grantRight = "scholarship-module--grant-right--gmet7";
export var hero = "scholarship-module--hero--I0SHp";
export var heroImage = "scholarship-module--hero-image--G-+KQ";
export var heroInfo = "scholarship-module--hero-info--3ov+B";
export var heroLinks = "scholarship-module--hero-links--qjUcK";
export var imageStackContainer = "scholarship-module--image-stack-container--Ar69n";
export var isa = "scholarship-module--isa--4XFSr";
export var isaContent = "scholarship-module--isa-content--x3Aol";
export var isaDetailLink = "scholarship-module--isa-detail-link--kvOpC";
export var isaIntro = "scholarship-module--isa-intro--k3BTz";
export var isaRight = "scholarship-module--isa-right--SHZm+";
export var needScholarships = "scholarship-module--need-scholarships--EcG43";
export var needScholarshipsLeft = "scholarship-module--need-scholarships-left--6bAL6";
export var needScholarshipsRight = "scholarship-module--need-scholarships-right--qfdXE";
export var offsetImage = "scholarship-module--offset-image--p2No-";
export var otherScholarships = "scholarship-module--other-scholarships--+k9IE";
export var otherScholarshipsLeft = "scholarship-module--other-scholarships-left--poRoU";
export var otherScholarshipsRight = "scholarship-module--other-scholarships-right--W56wL";
export var scholarships = "scholarship-module--scholarships--P3hR8";
export var sectionDescription = "scholarship-module--section-description--v8kmM";
export var sidebar = "scholarship-module--sidebar--fk4Lg";
export var sidebarHeaderImage = "scholarship-module--sidebar-header-image--0AjYT";
export var sidebarInfoContainer = "scholarship-module--sidebar-info-container--CroEY";
export var sidebarLinks = "scholarship-module--sidebar-links--LqHqB";
export var sidebarTitle = "scholarship-module--sidebar-title--mcuxb";
export var studentSuccessSection = "scholarship-module--student-success-section--TEYmA";
export var successBanner = "scholarship-module--success-banner--U+yeK";